const UserProfileInformationUpdate = {
  dropDown: function(selectorDropdown) {
    $(selectorDropdown).dropdown({
      placeholder: 'any'
    });
  },
  clickEditModal: function(selectorModalContainer, selectorModalButton) {
    $(selectorModalButton).on('click', function() {
      $(selectorModalContainer).addClass('loading');
    });
  },
  openEditModal: function(selectorModal, selectorDropdown, selectorModalContainer, selectorModalButton) {
    $(selectorModal).removeClass('loading').html(renderUserInformationEdit);
    UserProfileInformationUpdate.dropDown(selectorDropdown);
    UserProfileInformationUpdate.clickEditModal(selectorModalContainer, selectorModalButton);
  },
  openCompleteModal: function(selectorModal, selectorControl, selectorCloseButton) {
    $(selectorModal).removeClass('loading').html(renderUserInformationComplete);
    UserProfileInformationUpdate.closeModal(selectorControl, selectorCloseButton);
    UserProfileInformationUpdate.insertVisibilityTrigger('#visibility-trigger', '#fill_out_info');
  },
  closeModal: function(selectorControl, selectorClose) {
    $(selectorClose).on('click', function() {
      $(selectorControl).modaal('close');
    });
  },
  insertVisibilityTrigger: function(selectorContainer, selectorElement) {
    !$(selectorContainer).length && $('body').append(renderUserInformationVisibilityTriggerContainer);
    ($(selectorContainer).length && !$(selectorElement).length) && $(selectorContainer).append(renderUserInformationVisibilityTrigger);
  },
  loads: function() {
    userInformationState
      ? UserProfileInformationUpdate.openCompleteModal('.modaal-content-container', '#modal', '.modaal-container .close button')
      : UserProfileInformationUpdate.openEditModal('.modaal-content-container', '.user-information.edit select', '.user-information.edit', '.btn [type=submit]');
  },
  events: function() {
    UserProfileInformationUpdate.clickEditModal('.user-information.edit', '.btn [type=submit]');
  }
};

$(document).on('turbolinks:load', function() {
  UserProfileInformationUpdate.events();
});

window['UserProfileInformationUpdate'] = UserProfileInformationUpdate;
