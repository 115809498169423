const PlugInLazyLoad = {
  lazyLoaded: function(element, parentLoading) {
    $(element).removeAttr('data-src').removeAttr('data-was-processed').removeAttr('class');
    $(element).closest(parentLoading).removeClass('lazy-loading');
  },
  lazyLoad: function(selectorContainer, selectorParentLoading, selectorImage) {
    const image = `${selectorContainer} ${selectorParentLoading} ${selectorImage}`;

    if (!$(image).length) return;

    const lazyLoadAttr = {
      elements_selector: image,
      callback_loaded: function(element) {
        PlugInLazyLoad.lazyLoaded(element, selectorParentLoading);
      }
    };

    new LazyLoad(lazyLoadAttr);
  },
  loads: function() {
    PlugInLazyLoad.lazyLoad('#page header', '.logo a', '.lazy');
    PlugInLazyLoad.lazyLoad('#page header', '.img', '.lazy');
    PlugInLazyLoad.lazyLoad('#content .side', '.img', '.lazy');
    PlugInLazyLoad.lazyLoad('#page footer', '.logo a', '.lazy');
    PlugInLazyLoad.lazyLoad('#question', '.img', '.lazy');
    PlugInLazyLoad.lazyLoad('#comment', '.img', '.lazy');
    PlugInLazyLoad.lazyLoad('#home .user', '.img', '.lazy');
    PlugInLazyLoad.lazyLoad('#tag.show .user', '.img', '.lazy');
    PlugInLazyLoad.lazyLoad('#user', '.img', '.lazy');
    PlugInLazyLoad.lazyLoad('#contact', '.img', '.lazy');
    PlugInLazyLoad.lazyLoad('#dashboard', '.img', '.lazy');
    PlugInLazyLoad.lazyLoad('#profile', '.img', '.lazy');
    PlugInLazyLoad.lazyLoad('#general-notifications', '.img', '.lazy');
    PlugInLazyLoad.lazyLoad('#expert', '.img', '.lazy');
    PlugInLazyLoad.lazyLoad('#about-score', '.img', '.lazy');
  }
};

$(document).on('turbolinks:load', function() {
  PlugInLazyLoad.loads();
});
