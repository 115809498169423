const PlugInRippleEffects = {
  clickButton: function(selectorContainer, selectorButton, options) {
    if (!$(`${selectorContainer} ${selectorButton}`).length) return;

    Ripple(`${selectorContainer} ${selectorButton}`, options);
  },
  events: function() {
    const
      grayGhost = '#b7b7bd',
      redFreeSpeech = '#b80000';

    PlugInRippleEffects.clickButton('header .user .general-notification', '.icon', {
      background: grayGhost,
      opacity: 0.2
    });

    PlugInRippleEffects.clickButton('header .user .general-notification .unread', 'a', {
      background: grayGhost,
      opacity: 0.2
    });

    PlugInRippleEffects.clickButton('#home .question .sort .list', 'a', {
      background: redFreeSpeech,
      opacity: 0.1
    });

    PlugInRippleEffects.clickButton('#home .question .sort .list .active', 'a', {
      background: 'white',
      opacity: 0.2
    });

    PlugInRippleEffects.clickButton('#tag.show .question .sort .list', 'a', {
      background: redFreeSpeech,
      opacity: 0.1
    });

    PlugInRippleEffects.clickButton('#tag.show .question .sort .list .active', 'a', {
      background: 'white',
      opacity: 0.2
    });

    PlugInRippleEffects.clickButton('#question.show .answer .locked .unlock .social', 'a', {
      background: grayGhost,
      opacity: 0.2
    });

    PlugInRippleEffects.clickButton('#general-notifications .unread', 'a', {
      background: grayGhost,
      opacity: 0.2
    });
  }
};

$(document).on('turbolinks:load', function() {
  PlugInRippleEffects.events();
});
