import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel'

const LayoutContentSideAds = {
  init: function(settings) {
    LayoutContentSideAds.timeoutId = settings.timeoutId
    LayoutContentSideAds.windowWidth = settings.windowWidth
  },
  touchEvents: function(selector) {
    $(selector).on('touchmove', function(e) {
      e.preventDefault()
    })
  },
  initializeSlider: function(container, slider) {
    $(slider).on('initialized.owl.carousel', function() {
      $(container).removeClass('loading')
    })
  },
  configSlider: function(selectorContainer, selectorSlider) {
    if ($(selectorContainer).length && $(selectorSlider).length) {
      LayoutContentSideAds.initializeSlider(selectorContainer, selectorSlider)

      setTimeout(function() {
        $(selectorSlider).owlCarousel({
          autoplay: $(selectorContainer).length > 1,
          autoplayHoverPause: true,
          autoPlay: 3000,
          dotsEach: false,
          items: 1,
          nav: false,
          slideBy: 1,
          smartSpeed: 400,
          animateOut: 'fadeOut',
          loop: true,
        })
      }, 300)

      LayoutContentSideAds.touchEvents(selectorSlider)
    }
  },
  resizeSlider: function(selector) {
    if ($(selector).length && LayoutContentSideAds.windowWidth !== $(window).width()) {
      LayoutContentSideAds.blurSlider(selector, true)
      clearTimeout(LayoutContentSideAds.timeoutId)
      LayoutContentSideAds.timeoutId = setTimeout(function() {
        LayoutContentSideAds.blurSlider(selector, false)
      }, 300)
      LayoutContentSideAds.windowWidth = $(window).width()
    }
  },
  blurSlider: function(selector, state) {
    $(selector).toggleClass('resizing', state)
  },
  loads: function() {
    LayoutContentSideAds.configSlider('.ads .list', '.ads .owl-carousel')
  },
  resizingEvents: function() {
    LayoutContentSideAds.resizeSlider('.ads .list .owl-carousel')
  }
}

LayoutContentSideAds.init({
  timeoutId: null,
  windowWidth: $(window).width(),
})

$(document).on('turbolinks:load', function() {
  LayoutContentSideAds.loads()
})

$(window).resize(function() {
  LayoutContentSideAds.resizingEvents()
})
