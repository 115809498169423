// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require('turbolinks').start()

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.

const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

import './plugins'
import './plugins/lazyload'
import './plugins/modaal'
import './plugins/ripple-effects'
import './plugins/semantic-ui-dropdown'
import './plugins/turbolinks'
import './layouts/header'
import './layouts/header/answered_question_rate'
import './layouts/header/flash'
import './layouts/content/answered_question_rate'
import './layouts/content/side'
import './layouts/content/side/ads'
import './like_questions/create'
import './like_questions/destroy'
import './general_notifications/mark_as_read/update'
import './general_notifications/mark_as_seen/update'
import './useful_answers/create'
import './useful_answers/destroy'
import './users/profile/information/update'
import 'stylesheets/application'
