const LayoutContentSide = {
  clickTab: function(selectorTab, selectorContainer, selectorItem, selectorItemInner, selectorBtn) {
    if (!($(selectorTab).length && $(selectorContainer).length)) return;

    $(`${selectorTab} ${selectorItem}`).each(function(index, element) {
      const btn = $(element).find(selectorBtn);

      btn.click(function(event) {
        event.preventDefault();

        const
          btnName = $(element).attr('class').split(' ').pop(),
          container = $(`${selectorContainer} > ${selectorItem}.${btnName}`),
          containerInnerHeight = container.find(selectorItemInner).outerHeight();

        if ($(element).hasClass('active')) return;

        $(`${selectorTab} ${selectorItem}`).removeClass('active');
        $(`${selectorContainer} > ${selectorItem}`).removeClass('active');
        $(`${selectorContainer} > ${selectorItem}`).removeAttr('style');

        $(element).closest('.list')
          .removeClass('tab-totally')
          .removeClass('tab-monthly')
          .removeClass('tab-weekly')
          .addClass(`tab-${btnName}`);

        $(element).addClass('active');
        container.addClass('active');
        container.css({ height: `${containerInnerHeight}px` });
      });
    });
  },
  resizeTab: function(selectorContainer, selectorContainerName, selectorItem, selectorItemInner) {
    const containerItem = $(`${selectorContainer} ${selectorContainerName}`);

    if (!containerItem) return;

    containerItem.css({ height: `${containerItem.find(selectorItemInner).outerHeight()}px` });
  },
  events: function() {
    LayoutContentSide.clickTab('#content .side .scores .tab', '#content .side .scores .container', '.list > li', '.div', 'a');
  },
  resizingEvents: function() {
    LayoutContentSide.resizeTab('#content .side .scores .container', '.active', '.list > li', '.div');
  }
};

$(document).on('turbolinks:load', function() {
  LayoutContentSide.events();
});

$(window).resize(function() {
  LayoutContentSide.resizingEvents();
});
