import counterUp from 'counterup2'

const LayoutHeaderAnsweredQuestionRate = {
  init: function(settings) {
    LayoutHeaderAnsweredQuestionRate.mobileBreakpoint = settings.mobileBreakpoint;
    LayoutHeaderAnsweredQuestionRate.timeoutId = settings.timeoutId;
  },
  headerTopMargin: function(selectorHeader, selectorNavContainer, selectorContainer) {
    const
      header = $(selectorHeader),
      flash = $(`${selectorHeader} nav .flash`),
      flashHeight = flash.length ? flash.outerHeight() : 0,
      navContainer = $(`${selectorHeader} ${selectorNavContainer}`),
      navContainerHeight = navContainer.length ? navContainer.outerHeight() : 0,
      container = $(`${selectorContainer} .div`),
      containerHeight = container.length ? container.outerHeight() : 0,
      navHeight = flashHeight + navContainerHeight + containerHeight;

    header.addClass('changing');
    header.css({ marginTop: `${navHeight}px` });
    $('body').delay(600).show(function() {
      header.removeClass('changing');
    });
  },
  headerTopMarginRestore: function(selectorHeader, selectorNavContainer) {
    const
      header = $(selectorHeader),
      flash = $(`${selectorHeader} nav .flash`),
      flashHeight = flash.length ? flash.outerHeight() : 0,
      navContainer = $(`${selectorHeader} ${selectorNavContainer}`),
      navContainerHeight = navContainer.length ? navContainer.outerHeight() : 0,
      navHeight = flashHeight + navContainerHeight;

    header.addClass('changing');
    header.css({ marginTop: `${navHeight}px` });
    $('body').delay(600).show(function() {
      header.removeClass('changing');
    });
  },
  toggle: function(selectorContainer, state) {
    $(selectorContainer).toggleClass('show', state);
  },
  containerInnerHeight: function(selectorContainer) {
    const
      container = $(selectorContainer),
      containerInnerHeight = container.hasClass('show') ? $(`${selectorContainer} .div`).outerHeight() : 0;

    container.css({ height: `${containerInnerHeight}px` });
  },
  countUp: function(selectorContainer, selectorCount, options) {
    const count = document.querySelector(`${selectorContainer} ${selectorCount}`);

    counterUp(count, options);
  },
  autoOpen: function(selectorHeader, selectorNavContainer, selectorContainer, selectorCount, durationAutoOpen, durationAutoClose, countUpOptions) {
    if (!$(selectorContainer).length || $(window).width() > LayoutHeaderAnsweredQuestionRate.mobileBreakpoint) return;

    const durationFadeIn = 300;

    LayoutHeaderAnsweredQuestionRate.toggle(selectorContainer, false);
    clearTimeout(LayoutHeaderAnsweredQuestionRate.timeoutId);

    LayoutHeaderAnsweredQuestionRate.timeoutId = setTimeout(function() {
      LayoutHeaderAnsweredQuestionRate.headerTopMargin(selectorHeader, selectorNavContainer, selectorContainer);
      LayoutHeaderAnsweredQuestionRate.toggle(selectorContainer, true);
      LayoutHeaderAnsweredQuestionRate.containerInnerHeight(selectorContainer);
      LayoutHeaderAnsweredQuestionRate.countUp(selectorContainer, selectorCount, countUpOptions);
      LayoutHeaderAnsweredQuestionRate.autoClose(selectorHeader, selectorNavContainer, selectorContainer, durationAutoClose + durationFadeIn);
    }, durationAutoOpen);
  },
  autoClose: function(selectorHeader, selectorNavContainer, selectorContainer, duration) {
    LayoutHeaderAnsweredQuestionRate.toggle(selectorContainer, true);
    clearTimeout(LayoutHeaderAnsweredQuestionRate.timeoutId);

    LayoutHeaderAnsweredQuestionRate.timeoutId = setTimeout(function() {
      LayoutHeaderAnsweredQuestionRate.headerTopMarginRestore(selectorHeader, selectorNavContainer);
      LayoutHeaderAnsweredQuestionRate.toggle(selectorContainer, false);
      LayoutHeaderAnsweredQuestionRate.containerInnerHeight(selectorContainer);
    }, duration);
  },
  clickClose: function(selectorHeader, selectorNavContainer, selectorContainer, selectorBtn) {
    if (!$(selectorContainer).length || $(window).width() > LayoutHeaderAnsweredQuestionRate.mobileBreakpoint) return;

    const
      btn = $(`${selectorContainer} ${selectorBtn}`),
      containerVisible = $(selectorContainer).is(':visible');

    containerVisible && btn.click(function() {
      LayoutHeaderAnsweredQuestionRate.headerTopMarginRestore(selectorHeader, selectorNavContainer);
      LayoutHeaderAnsweredQuestionRate.toggle(selectorContainer, false);
      LayoutHeaderAnsweredQuestionRate.containerInnerHeight(selectorContainer);
    });
  },
  clickOutside: function(selectorHeader, selectorNavContainer, selectorContainer) {
    if (!$(selectorContainer).length || $(window).width() > LayoutHeaderAnsweredQuestionRate.mobileBreakpoint) return;

    $(document).click(function(event) {
      const
        target = $(event.target),
        container = $(selectorContainer),
        containerAround = target.closest(selectorContainer).length,
        containerVisible = $(selectorContainer).is(':visible'),
        leftClicked = (event.which === 1);

      if (!(container.hasClass('show') && !containerAround && containerVisible && leftClicked)) return;

      LayoutHeaderAnsweredQuestionRate.headerTopMarginRestore(selectorHeader, selectorNavContainer);
      LayoutHeaderAnsweredQuestionRate.toggle(selectorContainer, false);
      LayoutHeaderAnsweredQuestionRate.containerInnerHeight(selectorContainer);
    });
  },
  loads: function() {
    LayoutHeaderAnsweredQuestionRate.autoOpen('header', 'nav .container', '#answered-question-rate.mobile', '.main .rate strong', 10000, 5000, {
      'time': 1000,
      'delay': 10
    });
  },
  events: function() {
    LayoutHeaderAnsweredQuestionRate.clickClose('header', 'nav .container', '#answered-question-rate.mobile', '.close');
    LayoutHeaderAnsweredQuestionRate.clickOutside('header', 'nav .container', '#answered-question-rate.mobile');
  },
  resizingEvents: function() {
    LayoutHeaderAnsweredQuestionRate.resizingWindow('header', 'nav .container', '#answered-question-rate.mobile');
  }
};

LayoutHeaderAnsweredQuestionRate.init({
  mobileBreakpoint: 768,
  timeoutId: null
});

$(document).on('turbolinks:load', function() {
  LayoutHeaderAnsweredQuestionRate.loads();
  LayoutHeaderAnsweredQuestionRate.events();
});
