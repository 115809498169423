import counterUp from 'counterup2'

const LayoutContentAnsweredQuestionRate = {
  init: function(settings) {
    LayoutContentAnsweredQuestionRate.mobileBreakpoint = settings.mobileBreakpoint;
    LayoutContentAnsweredQuestionRate.timeoutId = settings.timeoutId;
  },
  toggle: function(selectorContainer, state) {
    $(selectorContainer).toggleClass('show', state);
  },
  countUp: function(selectorContainer, selectorCount, options) {
    const count = document.querySelector(`${selectorContainer} ${selectorCount}`);

    counterUp(count, options);
  },
  autoOpen: function(selectorContainer, selectorCount, durationAutoOpen, durationAutoClose, countUpOptions) {
    if (!$(selectorContainer).length || $(window).width() <= LayoutContentAnsweredQuestionRate.mobileBreakpoint) return;

    const durationFadeIn = 300;

    LayoutContentAnsweredQuestionRate.toggle(selectorContainer, false);
    clearTimeout(LayoutContentAnsweredQuestionRate.timeoutId);

    LayoutContentAnsweredQuestionRate.timeoutId = setTimeout(function() {
      LayoutContentAnsweredQuestionRate.toggle(selectorContainer, true);
      LayoutContentAnsweredQuestionRate.countUp(selectorContainer, selectorCount, countUpOptions);
      LayoutContentAnsweredQuestionRate.autoClose(selectorContainer, durationAutoClose + durationFadeIn);
    }, durationAutoOpen);
  },
  autoClose: function(selectorContainer, duration) {
    LayoutContentAnsweredQuestionRate.toggle(selectorContainer, true);
    clearTimeout(LayoutContentAnsweredQuestionRate.timeoutId);

    LayoutContentAnsweredQuestionRate.timeoutId = setTimeout(function() {
      LayoutContentAnsweredQuestionRate.toggle(selectorContainer, false);
    }, duration);
  },
  clickClose: function(selectorContainer, selectorBtn) {
    if (!$(selectorContainer).length || $(window).width() <= LayoutContentAnsweredQuestionRate.mobileBreakpoint) return;

    const
      btn = $(`${selectorContainer} ${selectorBtn}`),
      containerVisible = $(selectorContainer).is(':visible');

    containerVisible && btn.click(function() {
      LayoutContentAnsweredQuestionRate.toggle(selectorContainer, false);
    });
  },
  clickOutside: function(selectorContainer) {
    if (!$(selectorContainer).length || $(window).width() <= LayoutContentAnsweredQuestionRate.mobileBreakpoint) return;

    $(document).click(function(event) {
      const
        target = $(event.target),
        container = $(selectorContainer),
        containerAround = target.closest(selectorContainer).length,
        containerVisible = $(selectorContainer).is(':visible'),
        leftClicked = (event.which === 1);

      if (!(container.hasClass('show') && !containerAround && containerVisible && leftClicked)) return;

      LayoutContentAnsweredQuestionRate.toggle(selectorContainer, false);
    });
  },
  scrollWindow: function(selectorContainer, selectorFooter) {
    if (!$(selectorContainer).length || $(window).width() <= LayoutContentAnsweredQuestionRate.mobileBreakpoint) return;

    const
      windowTop = $(window).scrollTop(),
      windowHeight = $(window).height(),
      footerTop = $(selectorFooter).length ? $(selectorFooter).offset().top : 0,
      footerState = (windowTop + windowHeight) < footerTop,
      containerBottom = footerState ? 0 : (windowTop + windowHeight) - footerTop;

    $(selectorContainer).css('bottom', containerBottom);
  },
  loads: function() {
    LayoutContentAnsweredQuestionRate.autoOpen('#answered-question-rate.desktop', '.main .rate strong', 10000, 5000, {
      'time': 1000,
      'delay': 10
    });
  },
  events: function() {
    LayoutContentAnsweredQuestionRate.clickClose('#answered-question-rate.desktop', '.close');
    LayoutContentAnsweredQuestionRate.clickOutside('#answered-question-rate.desktop');
  },
  scrollingEvents: function() {
    LayoutContentAnsweredQuestionRate.scrollWindow('#answered-question-rate.desktop', 'footer');
  }
};

LayoutContentAnsweredQuestionRate.init({
  mobileBreakpoint: 768,
  timeoutId: null
});

$(document).on('turbolinks:load', function() {
  LayoutContentAnsweredQuestionRate.loads();
  LayoutContentAnsweredQuestionRate.events();
});

$(window).scroll(function() {
  LayoutContentAnsweredQuestionRate.scrollingEvents();
});
