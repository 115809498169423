const LayoutHeader = {
  fixed: function(selectorHeader, selectorNav, selectorFlash, selectorFlashClose, selectorNavContainer, selectorAnsweredQuestionRate) {
    if (!($(selectorHeader).length && $(selectorNavContainer).length)) return;

    $('body').delay(300).show(function() {
      const
        header = $(selectorHeader),
        flash = $(`${selectorHeader} ${selectorNav} ${selectorFlash}`),
        flashHeight = flash.length ? flash.outerHeight() : 0,
        navContainer = $(`${selectorHeader} ${selectorNav} ${selectorNavContainer}`),
        navContainerHeight = navContainer.length ? navContainer.outerHeight() : 0,
        answeredQuestionRate = $(`${selectorAnsweredQuestionRate} .div`),
        answeredQuestionRateHeight = $(selectorAnsweredQuestionRate).hasClass('show') && answeredQuestionRate.length ? answeredQuestionRate.outerHeight() : 0,
        navHeight = flashHeight + navContainerHeight + answeredQuestionRateHeight;

      header.addClass('fixed');
      header.css({ marginTop: `${navHeight}px` });
      header.hasClass('changing') && header.removeClass('changing');
    });
  },
  clickOutsideUserMenu: function(selectorNavContainer, selectorMenu) {
    $(document).click(function(event) {
      const
        target = $(event.target),
        container = $(selectorNavContainer),
        containerAround = target.closest(selectorNavContainer).length,
        containerVisible = $(selectorNavContainer).is(':visible'),
        leftClicked = (event.which === 1);

      if (!(container.hasClass('active') && !containerAround && containerVisible && leftClicked)) return;

      bodyScrollLock.enableBodyScroll(document.querySelector(selectorNavContainer));

      $(selectorNavContainer).removeClass('active');
      $(`${selectorNavContainer} ${selectorMenu}`).removeAttr('style');
    });
  },
  clickOutsideMenuMobile: function(selectorContainer, selectorNavContainer) {
    $(document).click(function(event) {
      const
        target = $(event.target),
        containerNav = $(`${selectorContainer} ${selectorNavContainer}`),
        containerNavAround = target.closest(selectorNavContainer).length,
        containerNavVisible = $(selectorNavContainer).is(':visible'),
        leftClicked = (event.which === 1);

      if (!(containerNav.hasClass('active') && !containerNavAround && containerNavVisible && leftClicked)) return;

      bodyScrollLock.enableBodyScroll(document.querySelector(selectorContainer));

      $(selectorContainer).removeClass('active');
      containerNav.removeClass('active');
    });
  },
  clickMenu: function(selectorNavContainer, selectorButton, selectorMenu, selectorMenuInner) {
    if (!$(selectorNavContainer).length) return;

    const
      container = $(selectorNavContainer),
      button = $(`${selectorNavContainer} ${selectorButton}`),
      menu = $(`${selectorNavContainer} ${selectorMenu}`),
      menuHeight = $(`${selectorNavContainer} ${selectorMenu} ${selectorMenuInner}`);

    button.click(function() {
      if (container.hasClass('active')) {
        bodyScrollLock.enableBodyScroll(document.querySelector(selectorNavContainer));

        container.removeClass('active');
        menu.removeClass('active')
        menu.removeAttr('style');

      } else {
        bodyScrollLock.disableBodyScroll(document.querySelector(selectorNavContainer));

        $(selectorNavContainer).removeClass('active');
        $(`${selectorNavContainer} ${selectorMenu}`).removeAttr('style');

        container.addClass('active');
        menu.addClass('active')
        menu.css({ height: `${menuHeight.outerHeight()}px` });
      }
    });

    LayoutHeader.clickOutsideUserMenu(selectorNavContainer, selectorMenu);
  },
  clickMenuMobile: function(selectorContainer, selectorNavContainer, selectorButton) {
    const
      container = $(selectorContainer),
      containerNav = $(`${selectorContainer} ${selectorNavContainer}`),
      button = $(`${selectorContainer} ${selectorNavContainer} ${selectorButton}`)

    if (!containerNav.length) return;

    button.click(function() {
      if (containerNav.hasClass('active')) {
        bodyScrollLock.enableBodyScroll(document.querySelector(selectorContainer));

        container.removeClass('active');
        containerNav.removeClass('active');

      } else {
        bodyScrollLock.disableBodyScroll(document.querySelector(selectorContainer));

        container.addClass('active');
        containerNav.addClass('active');
      }
    });

    LayoutHeader.clickOutsideMenuMobile(selectorContainer, selectorNavContainer);
  },
  closeMenu: function(selectorNavContainer, selectorButton, selectorMenu) {
    if (!($(selectorNavContainer).hasClass('active') && $(selectorNavContainer).length)) return;

    $(`${selectorNavContainer} ${selectorMenu}`).removeAttr('style');
    $(selectorNavContainer).removeClass('active');
  },
  closeMenuMobile: function(selectorContainer, selectorNavContainer) {
    if (!($(selectorNavContainer).hasClass('active') && $(selectorNavContainer).length)) return;

    $(selectorContainer).removeClass('active');
    $(`${selectorContainer} ${selectorNavContainer}`).removeClass('active');
  },
  loads: function() {
    LayoutHeader.fixed('header', 'nav', '.flash', '.flash .close', '.container', '#answered-question-rate.mobile');
  },
  events: function() {
    LayoutHeader.clickMenu('header .user .general-notification', '.icon', '.sub-list', '.div');
    LayoutHeader.clickMenu('header .user .menu', '.img', '.sub-list', 'ul');
    LayoutHeader.clickMenu('header nav .search', '.button', '.form', 'form');
    LayoutHeader.clickMenuMobile('header nav .container', '.mobile', '.hamburger', '.menu')
    LayoutHeader.clickMenuMobile('header nav .container', '.mobile', '.close')
  },
  resizingEvents: function() {
    LayoutHeader.closeMenu('header .user .general-notification.active', '.icon', '.sub-list');
    LayoutHeader.closeMenu('header .user .menu.active', '.img', '.sub-list');
    LayoutHeader.closeMenuMobile('header nav .container', '.mobile')
  }
};

$(document).on('turbolinks:load', function() {
  LayoutHeader.loads();
  LayoutHeader.events();
});

$(window).resize(function() {
  LayoutHeader.loads();
  LayoutHeader.resizingEvents();
});
