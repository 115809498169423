import 'modaal'
import 'modaal/dist/css/modaal.css'
import 'stylesheets/plugins/modaal'

const PlugInModaal = {
  lazyLoaded: function(element, parentLoading) {
    $(element).removeAttr('data-src').removeAttr('data-was-processed').removeAttr('class');
    $(element).closest(parentLoading).removeClass('lazy-loading');
  },
  lazyLoad: function(selectorContainer, selectorParentLoading, selectorImage) {
    const image = `${selectorContainer} ${selectorParentLoading} ${selectorImage}`;

    if (!$(image).length) return;

    const lazyLoadAttr = {
      elements_selector: image,
      callback_loaded: function(element) {
        PlugInModaal.lazyLoaded(element, selectorParentLoading);
      }
    };

    new LazyLoad(lazyLoadAttr);
  },
  clickButton: function(selectorContainer, selectorButton, options) {
    if (!$(`${selectorContainer} ${selectorButton}`).length) return;

    Ripple(`${selectorContainer} ${selectorButton}`, options);
  },
  open: function(selectorContainer, selectorOpenButton, selectorModal, state) {
    const openButton = $(`${selectorContainer} ${selectorOpenButton}`);

    if (!(state && openButton.length)) return;

    openButton.modaal({
      content_source: selectorModal,
      hide_close: true,
      after_open: function() {
        PlugInModaal.clickButton('.modaal-wrapper .modaal-content .btn .social', 'a', {
          background: '#b7b7bd',
          opacity: 0.2
        });
      }
    });
  },
  openAuto: function(selectorControl, selectorModal, selectorCloseButton, state, isLockedState = false) {
    const control = $(selectorControl);

    if (!(state && control.length)) return;

    control.modaal({
      content_source: selectorModal,
      hide_close: true,
      start_open: true,
      is_locked: isLockedState
    });

    /*$(document).on('click', selectorCloseButton, function() {
      control.modaal('close');
    });*/
  },
  openConfirmation: function(selectorContainer, selectorForm, selectorOpenButton, selectorModal, selectorImageContainer, selectorParentLoading, selectorImage) {
    const
      openButton = $(`${selectorContainer} ${selectorOpenButton}`),
      form = openButton.closest(selectorForm);

    if (!(openButton.length && form.length)) return;

    openButton.modaal({
      content_source: selectorModal,
      hide_close: true,
      after_open: function() {
        PlugInModaal.lazyLoad(selectorImageContainer, selectorParentLoading, selectorImage);
      },
      confirm_callback: function() {
        (form.get(0).checkValidity() || form.get(0).reportValidity()) && form.submit();
      }
    });
  },
  close: function(selectorContainer, selectorOpenButton, selectorCloseButton, selectorModal, state) {
    const openButton = $(`${selectorContainer} ${selectorOpenButton}`);

    if (!(state && openButton.length)) return;

    $(document).on('click', selectorCloseButton, function() {
      openButton.modaal('close');
    });
  },
  loads: function() {
    PlugInModaal.open('#question.show .answer .add', '.open button', '#modal .user', !userSignInState);

    PlugInModaal.open('header nav', '.question a', '#modal .user-question-under-limit', !userQuestionUnderLimit);

    PlugInModaal.open('#home .question', '.like a', '#modal .user', !userSignInState);
    PlugInModaal.open('#question.show', '.like a', '#modal .user', !userSignInState);
    PlugInModaal.open('#question.show', '.useful a', '#modal .user', !userSignInState);
    PlugInModaal.open('#tag.show', '.like a', '#modal .user', !userSignInState);
    PlugInModaal.open('#user .container .counter', '.like a', '#modal .user', !userSignInState);

    PlugInModaal.openAuto('#modal', '#modal .modal', '.modaal-container .close button', !userRegisteredInformation);

    PlugInModaal.openConfirmation('#question.new', 'form', '.btn [name="create"]', '#modal .modal-question', '.modaal-content-container', '.img', '.lazy');
    PlugInModaal.openConfirmation('#question.confirm', 'form', '.btn [name="create"]', '#modal .modal-question', '.modaal-content-container', '.img', '.lazy');
    PlugInModaal.openConfirmation('#withdraw.index', 'form', '.btn [name="destroy"]', '#modal .withdraw-destroy');
  },
  events: function() {
    PlugInModaal.close('#question.show .answer .add', '.open button', '.modaal-container .close button', '#modal .user', !userSignInState);

    PlugInModaal.close('header nav', '.question a', '.modaal-container .close button', '#modal .user-question-under-limit', !userQuestionUnderLimit);
    PlugInModaal.close('header nav', '.question a', '.modaal-container .confirm button', '#modal .user-question-under-limit', !userQuestionUnderLimit);

    PlugInModaal.close('#home .question', '.like a', '.modaal-container .close button', '#modal .user', !userSignInState);
    PlugInModaal.close('#question.show', '.like a', '.modaal-container .close button', '#modal .user', !userSignInState);
    PlugInModaal.close('#question.show', '.useful a', '.modaal-container .close button', '#modal .user', !userSignInState);
    PlugInModaal.close('#tag.show', '.like a', '.modaal-container .close button', '#modal .user', !userSignInState);
    PlugInModaal.close('#user .container .counter', '.like a', '.modaal-container .close button', '#modal .user', !userSignInState);

    PlugInModaal.close('#question.new', '.btn [name="create"]', '.modaal-container .close button', '#modal .modal-question', true);
    PlugInModaal.close('#question.confirm', '.btn [name="create"]', '.modaal-container .close button', '#modal .modal-question', true);
    PlugInModaal.close('#withdraw.index', '.btn [name="destroy"]', '.modaal-container .close button', '#modal .withdraw-destroy', true);
  }
};

$(document).on('turbolinks:load', function() {
  PlugInModaal.loads();
  PlugInModaal.events();
});
