const GeneralNotificationMarkAsReadUpdate = {
  flashInsert: function(selectorHeader, selectorNav, selectorFlash, renderFlash) {
    $(selectorFlash).length === 0 && $(selectorNav).prepend(renderFlash);
    $(selectorFlash).addClass('disable-auto-remove').css('height', 0);
  },
  flashAuto: function(selectorHeader, selectorNav, selectorFlash, duration) {
    $('body').delay(duration).show(function() {
      $(`${selectorHeader} ${selectorNav} ${selectorFlash}`).removeClass('hidden').removeAttr('style');
    });
  },
  flashClose: function(selectorHeader, selectorNav, selectorFlash, selectorFlashClose) {
    $(`${selectorHeader} ${selectorNav} ${selectorFlashClose}`).click(function() {
      $(`${selectorHeader} ${selectorNav} ${selectorFlash}`).addClass('hidden');
    });
  },
  flashOpen: function(selectorHeader, selectorNav, selectorFlash, selectorFlashClose) {
    if (!userSignInState) return;

    LikeQuestionCreate.flashInsert(selectorHeader, selectorNav, selectorFlash, renderFlash);
    LikeQuestionCreate.flashAuto(selectorHeader, selectorNav, selectorFlash, 300);
    LikeQuestionCreate.flashClose(selectorHeader, selectorNav, selectorFlash, selectorFlashClose);
  },
  success: function() {
    const
      generalNotificationTargetURL = `${location.origin}${generalNotificationTargetPath}`,
      notificationUnread = $(`#notification-${generalNotificationId}.unread`);

    notificationUnread.length && notificationUnread.removeClass('unread');

    location.replace(generalNotificationTargetURL);
    `${location.pathname}${location.hash}` === generalNotificationTargetPath && location.reload();
  },
  loads: function() {
    generalNotificationState ?
      GeneralNotificationMarkAsReadUpdate.success() :
      GeneralNotificationMarkAsReadUpdate.flashOpen('header', 'nav', '.flash', '.flash .close');
  }
};

window['GeneralNotificationMarkAsReadUpdate'] = GeneralNotificationMarkAsReadUpdate;
