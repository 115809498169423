const UsefulAnswerCreate = {
  flashInsert: function(selectorHeader, selectorNav, selectorFlash, renderFlash) {
    $(selectorFlash).length === 0 && $(selectorNav).prepend(renderFlash);
    $(selectorFlash).addClass('disable-auto-remove').css('height', 0);
  },
  flashAuto: function(selectorHeader, selectorNav, selectorFlash, duration) {
    $('body').delay(duration).show(function() {
      $(`${selectorHeader} ${selectorNav} ${selectorFlash}`).removeClass('hidden').removeAttr('style');
    });
  },
  flashClose: function(selectorHeader, selectorNav, selectorFlash, selectorFlashClose) {
    $(`${selectorHeader} ${selectorNav} ${selectorFlashClose}`).click(function() {
      $(`${selectorHeader} ${selectorNav} ${selectorFlash}`).addClass('hidden');
    });
  },
  flashOpen: function(selectorHeader, selectorNav, selectorFlash, selectorFlashClose, selectorButtonContainer) {
    if (!(userSignInState && $(selectorButtonContainer).length)) return;

    answerId && $(`${selectorButtonContainer} [data-answer-id=${answerId}]`).removeClass('loading');

    UsefulAnswerCreate.flashInsert(selectorHeader, selectorNav, selectorFlash, renderFlash);
    UsefulAnswerCreate.flashAuto(selectorHeader, selectorNav, selectorFlash, 300);
    UsefulAnswerCreate.flashClose(selectorHeader, selectorNav, selectorFlash, selectorFlashClose);
  },
  success: function(selectorHeader, selectorNav, selectorFlash, selectorFlashClose, selectorButtonContainer) {
    if (!(userSignInState && $(selectorButtonContainer).length)) return;

    const button = $(`${selectorButtonContainer} [data-answer-id=${answerId}]`);

    button.each(function(index, element) {
      const
        useful = $(element).closest('.useful'),
        tooltip = useful.find('.tooltip');

      useful.addClass('usefuled').removeClass('loading');
      tooltip.addClass('active');
      $('body').delay(6000).show(function() { tooltip.removeClass('active') });

      $(element)
        .attr('data-method', 'delete')
        .next().attr('title', usefulAnswerCount).html(usefulAnswerCountHumanize);

      $(element).replaceWith($(element).clone());
    });
  },
  loads: function() {
    usefulAnswerState ?
      UsefulAnswerCreate.success('header', 'nav', '.flash', '.flash .close', '#question.show') :
      UsefulAnswerCreate.flashOpen('header', 'nav', '.flash', '.flash .close', '#question.show');
  }
};

window['UsefulAnswerCreate'] = UsefulAnswerCreate;
