const LayoutHeaderFlash = {
  remove: function(selectorHeader, selectorNav, selectorFlash, selectorContainer) {
    if (!$(selectorFlash).length) return;

    const
      header = $(selectorHeader),
      flash = $(`${selectorHeader} ${selectorNav} ${selectorFlash}`),
      container = $(`${selectorHeader} ${selectorNav} ${selectorContainer}`),
      containerHeight = container.length ? container.outerHeight() : 0;

    header.addClass('changing');
    header.css({ marginTop: `${containerHeight}px` });
    $('body').delay(600).show(function() {
      header.removeClass('changing');
      flash.not('.disable-auto-remove').remove();
    });
  },
  auto: function(selectorHeader, selectorNav, selectorFlash, selectorContainer, duration) {
    $('body').delay(duration).show(function() {
      $(`${selectorHeader} ${selectorNav} ${selectorFlash}`).not('.disable-auto-remove').addClass('hidden');
      LayoutHeaderFlash.remove(selectorHeader, selectorNav, selectorFlash, selectorContainer);
    });
  },
  close: function(selectorHeader, selectorNav, selectorFlash, selectorFlashClose, selectorContainer) {
    $(`${selectorHeader} ${selectorNav} ${selectorFlashClose}`).click(function() {
      $(`${selectorHeader} ${selectorNav} ${selectorFlash}`).addClass('hidden');
      LayoutHeaderFlash.remove(selectorHeader, selectorNav, selectorFlash, selectorContainer);
    });
  },
  loads: function() {
    LayoutHeaderFlash.auto('header', 'nav', '.flash', '.container', 6000);
  },
  events: function() {
    LayoutHeaderFlash.close('header', 'nav', '.flash', '.flash .close', '.container');
  }
};

$(document).on('turbolinks:load', function() {
  LayoutHeaderFlash.loads();
  LayoutHeaderFlash.events();
});
