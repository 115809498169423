import 'semantic-ui-dropdown/dropdown'
import 'semantic-ui-dropdown/dropdown.css'
import 'semantic-ui-transition/transition'
import 'semantic-ui-transition/transition.css'

const PlugInSemanticUiDropdown = {
  load: function(selectorDropdown) {
    $(selectorDropdown).dropdown({
      placeholder: 'any'
    });
  },
  loads: function() {
    PlugInSemanticUiDropdown.load('.user-information.edit select');
    PlugInSemanticUiDropdown.load('#profile.edit select');
  }
};

$(document).on('turbolinks:load', function() {
  PlugInSemanticUiDropdown.loads();
});
