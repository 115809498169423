const GeneralNotificationMarkAsSeenUpdate = {
  flashInsert: function(selectorHeader, selectorNav, selectorFlash, renderFlash) {
    $(selectorFlash).length === 0 && $(selectorNav).prepend(renderFlash);
    $(selectorFlash).addClass('disable-auto-remove').css('height', 0);
  },
  flashAuto: function(selectorHeader, selectorNav, selectorFlash, duration) {
    $('body').delay(duration).show(function() {
      $(`${selectorHeader} ${selectorNav} ${selectorFlash}`).removeClass('hidden').removeAttr('style');
    });
  },
  flashClose: function(selectorHeader, selectorNav, selectorFlash, selectorFlashClose) {
    $(`${selectorHeader} ${selectorNav} ${selectorFlashClose}`).click(function() {
      $(`${selectorHeader} ${selectorNav} ${selectorFlash}`).addClass('hidden');
    });
  },
  flashOpen: function(selectorHeader, selectorNav, selectorFlash, selectorFlashClose) {
    if (!userSignInState) return;

    LikeQuestionCreate.flashInsert(selectorHeader, selectorNav, selectorFlash, renderFlash);
    LikeQuestionCreate.flashAuto(selectorHeader, selectorNav, selectorFlash, 300);
    LikeQuestionCreate.flashClose(selectorHeader, selectorNav, selectorFlash, selectorFlashClose);
  },
  clickButton: function(selectorContainer, selectorButton, options) {
    if (!$(`${selectorContainer} ${selectorButton}`).length) return;

    Ripple(`${selectorContainer} ${selectorButton}`, options);
  },
  clickMenu: function(selectorNavContainer, selectorButton, selectorMenu, selectorMenuInner) {
    if (!$(selectorNavContainer).length) return;

    const
      container = $(selectorNavContainer),
      button = $(`${selectorNavContainer} ${selectorButton}`),
      menu = $(`${selectorNavContainer} ${selectorMenu}`),
      menuHeight = $(`${selectorNavContainer} ${selectorMenu} ${selectorMenuInner}`);

    button.click(function() {
      if (container.hasClass('active')) {
        bodyScrollLock.enableBodyScroll(document.querySelector(selectorNavContainer));

        container.removeClass('active');
        menu.removeAttr('style');
      } else {
        bodyScrollLock.disableBodyScroll(document.querySelector(selectorNavContainer));

        $(selectorNavContainer).removeClass('active');
        $(`${selectorNavContainer} ${selectorMenu}`).removeAttr('style');

        container.addClass('active');
        menu.css({ height: `${menuHeight.outerHeight()}px` });
      }
    });
  },
  success: function(selectorNavContainer, selectorButton, selectorMenu, selectorMenuInner) {
    const
      button = $(`${selectorNavContainer} ${selectorButton}`),
      grayGhost = '#b7b7bd';

    if (generalNotificationSeenAtState) return;

    button.removeClass('notice');
    button.replaceWith(button.clone().html(renderGeneralNotificationIcon));

    GeneralNotificationMarkAsSeenUpdate.clickButton(selectorNavContainer, selectorButton, {
      background: grayGhost,
      opacity: 0.2
    });

    GeneralNotificationMarkAsSeenUpdate.clickMenu(selectorNavContainer, selectorButton, selectorMenu, selectorMenuInner);
  },
  loads: function() {
    generalNotificationState ?
      GeneralNotificationMarkAsSeenUpdate.success('header .user .general-notification', '.icon', '.sub-list', '.div') :
      GeneralNotificationMarkAsSeenUpdate.flashOpen('header', 'nav', '.flash', '.flash .close');
  }
};

window['GeneralNotificationMarkAsSeenUpdate'] = GeneralNotificationMarkAsSeenUpdate;
