const LikeQuestionDestroy = {
  flashInsert: function(selectorHeader, selectorNav, selectorFlash, renderFlash) {
    $(selectorFlash).length === 0 && $(selectorNav).prepend(renderFlash);
    $(selectorFlash).addClass('disable-auto-remove').css('height', 0);
  },
  flashAuto: function(selectorHeader, selectorNav, selectorFlash, duration) {
    $('body').delay(duration).show(function() {
      $(`${selectorHeader} ${selectorNav} ${selectorFlash}`).removeClass('hidden').removeAttr('style');
    });
  },
  flashClose: function(selectorHeader, selectorNav, selectorFlash, selectorFlashClose) {
    $(`${selectorHeader} ${selectorNav} ${selectorFlashClose}`).click(function() {
      $(`${selectorHeader} ${selectorNav} ${selectorFlash}`).addClass('hidden');
    });
  },
  flashOpen: function(selectorHeader, selectorNav, selectorFlash, selectorFlashClose, selectorButtonContainer) {
    if (!(userSignInState && $(selectorButtonContainer).length)) return;

    questionId && $(`${selectorButtonContainer} [data-question-id=${questionId}]`).removeClass('loading');

    LikeQuestionDestroy.flashInsert(selectorHeader, selectorNav, selectorFlash, renderFlash, 300);
    LikeQuestionDestroy.flashAuto(selectorHeader, selectorNav, selectorFlash, 300);
    LikeQuestionDestroy.flashClose(selectorHeader, selectorNav, selectorFlash, selectorFlashClose);
  },
  success: function(selectorHeader, selectorNav, selectorFlash, selectorFlashClose, selectorButtonContainer) {
    if (!(userSignInState && $(selectorButtonContainer).length)) return;

    const button = $(`${selectorButtonContainer} [data-question-id=${questionId}]`);

    button.each(function(index, element) {
      $(element)
        .removeClass('liked')
        .removeClass('loading')
        .attr('data-method', 'post')
        .next().attr('title', likeQuestionCount).html(likeQuestionCountHumanize);

      $(element).replaceWith($(element).clone());
    });
  },
  loads: function() {
    questionState ?
      LikeQuestionDestroy.success('header', 'nav', '.flash', '.flash .close', '#home .question') :
      LikeQuestionDestroy.flashOpen('header', 'nav', '.flash', '.flash .close', '#home .question');

    questionState ?
      LikeQuestionDestroy.success('header', 'nav', '.flash', '.flash .close', '#question.show') :
      LikeQuestionDestroy.flashOpen('header', 'nav', '.flash', '.flash .close', '#question.show');

    questionState ?
      LikeQuestionDestroy.success('header', 'nav', '.flash', '.flash .close', '#tag.show') :
      LikeQuestionDestroy.flashOpen('header', 'nav', '.flash', '.flash .close', '#tag.show');

    questionState ?
      LikeQuestionDestroy.success('header', 'nav', '.flash', '.flash .close', '#dashboard') :
      LikeQuestionDestroy.flashOpen('header', 'nav', '.flash', '.flash .close', '#dashboard');

    questionState ?
      LikeQuestionDestroy.success('header', 'nav', '.flash', '.flash .close', '#user') :
      LikeQuestionDestroy.flashOpen('header', 'nav', '.flash', '.flash .close', '#user');
  }
}

window['LikeQuestionDestroy'] = LikeQuestionDestroy;
