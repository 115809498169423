const PlugInLazyLoad = {
  scrollRestoration: function(){
    'scrollRestoration' in window.history && (history.scrollRestoration = 'manual')
  },
  loads: function(){
    PlugInLazyLoad.scrollRestoration();
  }
};

$(document).on('turbolinks:load', function() {
  PlugInLazyLoad.loads();
});
